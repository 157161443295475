import { Injectable } from '@angular/core';
import { SearchCondition } from 'src/app/shared/model/search-condition.model';
import { SearchCount } from 'src/app/shared/model/search-count.model';
import { SearchPage } from 'src/app/shared/model/search-page.model';
import { Observable, Subject, ReplaySubject, of } from 'rxjs';
import { PageInfo } from 'src/app/shared/model/page-info.model';
// Rxjs
import { switchMap, tap } from 'rxjs/operators';
import { API_SERVER_URL } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from '../http-header.service';
import { SearchTag } from '../../model/search-tag.model';
// -----------------------------------------------------------------------------------------
export interface SearchParams {
  id: string;
  additionalParams: object;
}
export abstract class SearchService {
  allSkills: string[][] = [];
  constructor(private header: HttpHeaderService, private http: HttpClient) {
    this.requestGet('api/v1/skill_candidates').subscribe(res => {
      this.allSkills = res.body.skills;
    });
  }

  initCondition = {};
  initPage = {};
  initPerPage = {};
  initSearchIds = [];
  selfServiceName = '';

  // subject関連
  public search$ = new ReplaySubject<SearchParams>(1);
  public searchSub = this.search$.asObservable().pipe(
    switchMap(doSearchParams => {
      return of(doSearchParams);
    })
  );

  public searchSubject = new Subject<any>();
  public searchState = this.searchSubject.asObservable();

  // 検索開始
  public searchStartSubject = new Subject<any>();
  public searchStartState = this.searchStartSubject.asObservable();

  // 検索リセット
  public searchResetSubject = new Subject<any>();
  public searchResetState = this.searchResetSubject.asObservable();

  // 抽象メソッド：サブクラスにて実装
  search(id?: string, sortParams?: object): Observable<any> {
    // サブクラスでのAPI呼び出しをインターセプト
    return this.doSearch(id, sortParams).pipe(
      tap(res => {
        if (
          sessionStorage.getItem('searchPage' + this.selfServiceName) === null
        ) {
          // 初回読込：ページング情報がセッションに存在しない場合、レスポンス情報から作成→セッション保存
          this.setSearchCount(
            new SearchCount({
              startCount: res.body.pageInfo.start_count,
              endCount: res.body.pageInfo.end_count,
              totalCount: res.body.pageInfo.total,
              perPage: this.initPerPage['perPage']
            })
          );
        }
      })
    );
  }

  abstract doSearch(id?: string, sortParams?: object): Observable<any>;

  // 検索条件を組み合わせハッシュを返す
  getSearchParameters(sortParams?: object) {
    const condition = this.getSearchCondition();
    const count = this.getSearchCount().getParams();
    const page = this.getSearchPage().getParams();
    const searchTags = { search_tag_ids: this.getSearchTagIds() };
    return Object.assign(condition, count, page, searchTags, sortParams);
  }

  getSearchParams(): any {
    const sessionStorageSearchParams = sessionStorage.getItem(
      `${this.constructor.name}SearchParams`
    );
    if (sessionStorageSearchParams) {
      return JSON.parse(sessionStorageSearchParams);
    }
  }

  // ページ表示に必要なpageInfoを返す
  getPageInfo(): PageInfo {
    return new PageInfo(this.getSearchCount(), this.getSearchPage());
  }
  // セッター（sessionStrageに保存）
  public setSearchCondition(searchCondition: SearchCondition): void {
    sessionStorage.setItem(
      'searchCondition' + this.selfServiceName,
      JSON.stringify(searchCondition)
    );
  }
  public setSearchCount(searchCount: SearchCount): void {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    sessionStorage.setItem(
      'searchCount' + this.selfServiceName,
      JSON.stringify(searchCount)
    );
  }
  public setSearchPage(searchPage: SearchPage): void {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    sessionStorage.setItem(
      'searchPage' + this.selfServiceName,
      JSON.stringify(searchPage)
    );
  }
  public setSearchTags(searhTagIds: Array<number>): void {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    sessionStorage.setItem(
      'searchTagIds' + this.selfServiceName,
      JSON.stringify(searhTagIds)
    );
  }

  // ゲッター（sessionStrageから取得）
  public getSearchCondition(): SearchCondition {
    return new SearchCondition(
      JSON.parse(
        sessionStorage.getItem('searchCondition' + this.selfServiceName) ||
          JSON.stringify(this.initCondition)
      )
    );
  }
  public getSearchCount(): SearchCount {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    return new SearchCount(
      JSON.parse(
        sessionStorage.getItem('searchCount' + this.selfServiceName) ||
          JSON.stringify(this.initPerPage)
      )
    );
  }
  public getSearchPage(): SearchPage {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    return new SearchPage(
      JSON.parse(
        sessionStorage.getItem('searchPage' + this.selfServiceName) ||
          JSON.stringify(this.initPage)
      )
    );
  }
  public getSearchTagIds(): Array<number> {
    if (this.selfServiceName === 'SearchOfferWorkerService') {
      this.selfServiceName = 'SearchOfferProjectService';
    }
    return JSON.parse(
      sessionStorage.getItem('searchTagIds' + this.selfServiceName)
    );
  }

  // getとpostの重複記述を圧縮
  requestGet(requestDestination: string, params?: any): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.get<any>(url, this.header.getHttpOptions(params));
  }

  requestPost(requestDestination: string, params?: object): Observable<any> {
    const url = API_SERVER_URL + requestDestination;
    return this.http.post<any>(url, params, this.header.getHttpOptions());
  }

  updateActionLogClickOpenDetail(params) {
    const url =
      API_SERVER_URL + 'api/v1/action_log_for_detail/create_click_open_log';
    return this.http.post<any>(url, params, this.header.getHttpOptions());
  }
}

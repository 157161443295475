import { OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserRegistrationService } from '../../shared/service/user-registration.service';
import { CompanyService } from '../../shared/service/company.service';
import { ToastrService } from 'ngx-toastr';
import { SyncToStgService } from 'src/app/shared/service/sync-to-stg.service';
var RegistrationComponent = /** @class */ (function () {
    function RegistrationComponent(fb, userRegistrationService, companyService, toastrService, syncToStgService) {
        this.fb = fb;
        this.userRegistrationService = userRegistrationService;
        this.companyService = companyService;
        this.toastrService = toastrService;
        this.syncToStgService = syncToStgService;
        this.freezeUser = new FormControl(-1);
        this.company_selected = '';
        this.userList = [];
        // setup form
        // one company can have multiple users(FormArray)
        this.newUserForm = this.fb.group({
            company_id: ['', Validators.required],
            company_stg_id: ['0', Validators.required],
            company_detail: this.fb.group({
                name: [''],
                company_code: [''],
                mail_domain: [''],
                representative_mail_account: ['']
            }),
            users: this.fb.array([this.NewUser()])
        });
    }
    Object.defineProperty(RegistrationComponent.prototype, "users", {
        get: function () {
            return this.newUserForm.get('users');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "company_id", {
        get: function () {
            return this.newUserForm.get('company_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "company_detail", {
        get: function () {
            return this.newUserForm.get('company_detail');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistrationComponent.prototype, "company_stg_id", {
        get: function () {
            return this.newUserForm.get('company_stg_id');
        },
        enumerable: true,
        configurable: true
    });
    RegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isSTG = this.syncToStgService.needSync();
        this.needSync = this.syncToStgService.needSync();
        this.getCompanyList().then(function () {
            _this.loadUsers();
            _this.company_id.valueChanges.subscribe(function (val) { return _this.companySelectChange(); });
        }).catch(function (err) {
            console.error('Error loading company list', err);
        });
    };
    RegistrationComponent.prototype.NewUser = function () {
        return this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            user_code: ['', Validators.required],
            auth: [2, Validators.required],
            password: ['Abcd1234', Validators.required],
            password_confirm: ['Abcd1234', Validators.required],
            gross_margin_setting: ['5', Validators.required]
        });
    };
    RegistrationComponent.prototype.getCompanyList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.companyService.getCompanyList().subscribe({
                next: function (res) {
                    _this.companies = res.body;
                    _this.companies.unshift({ company_code: 'not exist', id: '-1' });
                    _this.company_id.setValue(_this.companies[0].id);
                    resolve();
                },
                error: function (err) {
                    _this.toastrService.warning("Can't get company list!");
                    console.warn("Can't get company list!");
                    console.warn(err);
                    reject(err);
                }
            });
            if (_this.isSTG && _this.needSync) {
                _this.syncToStgService.getCompanyList().subscribe({
                    next: function (res) {
                        _this.companies_stg = res.body;
                        _this.companies_stg.unshift({ company_code: 'not exist', id: '-1' });
                        _this.company_stg_id.setValue(_this.companies_stg[0].id);
                        resolve();
                    },
                    error: function (err) {
                        _this.toastrService.warning("STG_Can't get company list!");
                        console.warn("STG_Can't get company list!");
                        console.warn(err);
                        reject(err);
                    }
                });
            }
        });
    };
    RegistrationComponent.prototype.addNewUser = function () {
        this.users.push(this.NewUser());
    };
    RegistrationComponent.prototype.deleteUser = function (num) {
        this.users.removeAt(num);
    };
    RegistrationComponent.prototype.companySelectChange = function () {
        var _this = this;
        var companySelected = this.companies.find(function (company) { return String(company.id) === _this.company_id.value; });
        if (companySelected) {
            console.log("companySelected.company_code => " + companySelected.company_code);
            var companyStgSelected = this.companies_stg.find(function (company_stg) { return company_stg.company_code === companySelected.company_code; });
            console.log("companyStgSelected.id => " + companyStgSelected.id);
            if (companyStgSelected) {
                console.log("companyStgSelected true");
                this.company_stg_id.setValue(companyStgSelected.id);
            }
            else {
                this.company_stg_id.setValue('-1');
                console.log("companyStgSelected false");
            }
        }
    };
    RegistrationComponent.prototype.onSubmit = function () {
        this.userRegistrationService.createNewUsers(this.newUserForm.value);
        if (this.isSTG && this.needSync) {
            this.userRegistrationService.createNewUsers(this.newUserForm.value, '_STG');
        }
    };
    RegistrationComponent.prototype.loadUsers = function () {
        var _this = this;
        this.userRegistrationService.getUserList().subscribe(function (res) {
            _this.userList = res.body;
            console.log(_this.userList);
        });
    };
    RegistrationComponent.prototype.freeze = function () {
        var _this = this;
        this.userRegistrationService
            .freezeUser(this.freezeUser.value)
            .subscribe(function (res) {
            _this.userList = res.body;
            _this.freezeUser.reset();
            _this.toastrService.success('freeze switch success');
        });
    };
    return RegistrationComponent;
}());
export { RegistrationComponent };

import { MatchingOffer } from 'src/app/shared/model/matching-offer.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVER_URL } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
// Rxjs
import { map, filter } from 'rxjs/operators';
// model
import { OfferPair } from 'src/app/shared/model/offerPair.model';
import { Offer } from '../model/offer.model';

import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClientAbstract } from './http-client-abstract';
import { AccountService } from './account.service';

import { ToastrService } from 'ngx-toastr';
import { GoogleAnalysticsService } from 'src/app/shared/service/google-analystics.service';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class OfferService extends HttpClientAbstract {
  private sharedOffersSubject = new Subject<any>();
  public manualOfferRefreshPage = new Subject<any>();

  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private googleAnalysticsService: GoogleAnalysticsService
  ) {
    super(header, http);
  }

  public getOfferInfo(offerId: string, offerType: string): Observable<any> {
    const params = { offer_type: offerType };
    return super.requestGet('api/v1/offer/' + offerId, params);
  }

  public getSharedOffers(): Observable<any> {
    this.refreshSharedOffersList();
    return this.sharedOffersSubject.asObservable();
  }

  public refreshSharedOffersList(): void {
    super.requestGet('api/v1/offer/shared_offers').subscribe(res => {
      this.sharedOffersSubject.next(res);
    });
  }

  getSummary(): Observable<any> {
    return super.requestGetfile('api/v1/offer_summary');
  }
  // 人材確認依頼
  confirmWorker(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/confirm_worker', offer);
  }
  // 案件確認依頼
  confirmProject(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/confirm_project', offer);
  }
  // 検討中
  consider(offerPair: OfferPair, projectAIMailId, workerAIMailId): void {
    super.requestPost('api/v1/offer/consider', offerPair).subscribe(
      res => {
        this.toastrService.success('提案管理に送りました');
        this.refreshSharedOffersList();
        this.googleAnalysticsService.eventEmit(
          '提案管理ボタン',
          projectAIMailId,
          workerAIMailId,
          0
        );
      },
      err => {
        this.toastrService.error('提案管理に送信に失敗しました');
      }
    );
  }
  consider_ai_id(offerForm: FormGroup): void {
    const offerPair = offerForm.value;
    super.requestPost('api/v1/offer/consider', offerPair).subscribe({
      next: res => {
        this.toastrService.success('保存しました');
        this.googleAnalysticsService.eventEmit(
          '手動入力提案管理ボタン',
          offerPair['worker_ai_mail_id'],
          offerPair['project_ai_mail_id'],
          0
        );
        this.manualOfferRefreshPage.next();
        offerForm.reset();
      },
      error: err => {
        switch (err.status) {
          case 404:
            this.toastrService.error(
              'IDのご確認をお願いします',
              '保存に失敗しました'
            );
            break;

          case 409:
            this.toastrService.warning('提案は既に存在しています');
            break;

          default:
            this.toastrService.error('保存に失敗しました');
        }
      }
    });
  }
  // 案件募集終了
  cancelProject(offerPair: OfferPair): Observable<any> {
    return super.requestPost('api/v1/offer/cancel_project', offerPair);
  }
  // 人材募集終了
  cancelWorker(offerPair: OfferPair): Observable<any> {
    return super.requestPost('api/v1/offer/cancel_worker', offerPair);
  }

  // #####################################
  // 以下オファー画面より
  // #####################################
  resetOffer(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/reset_offer', offer);
  }
  deleteOffer(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/delete_offer', offer);
  }
  cancelOffer(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/cancel_offer', offer);
  }
  rejectOfferWorker(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/reject_offer_worker', offer);
  }
  rejectOfferProject(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/reject_offer_project', offer);
  }
  failedOffer(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/failed_offer', offer);
  }
  acceptedOfferWorker(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/accepted_offer_worker', offer);
  }
  acceptedOfferProject(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/accepted_offer_project', offer);
  }
  succeededOffer(offer: Offer): Observable<any> {
    return super.requestPost('api/v1/offer/succeeded_offer', offer);
  }
  checkWorkerOrProject(paramsAddCheckSide): Observable<any> {
    return super.requestPost(
      'api/v1/offer/send_checking_mail',
      paramsAddCheckSide
    );
  }
  // 承諾・辞退入力画面
  approveConfirmationResponse(params): Observable<any> {
    return super.requestGet(
      `api/v1/offer/approve_confirmation_response?token=${params.token}`
    );
  }

  // #####################################
  // 以下オファーに対する承諾回答
  // #####################################
  answerForApproveQuestionRequest(params): Observable<any> {
    return super.requestPost(`api/v1/approve_func_question_answer`, params);
  }

  // ############################
  // 提案管理のステータス表示ロジック
  // ############################

  isProjectSide(mailType: string): boolean {
    return mailType === 'project';
  }

  // 提案管理に送信されているか
  isOffered(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status !== null;
  }

  // 検討中
  isConsidering(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status === 'considering';
  }
  // BP連絡待ち
  isWaitingQuery(matchingOffer: MatchingOffer, mailType: string): boolean {
    if (this.isProjectSide(mailType)) {
      // tslint:disable-next-line:max-line-length
      return (
        !matchingOffer.send_mail_project &&
        !matchingOffer.approved_project &&
        !matchingOffer.rejected_project &&
        this.isOffered(matchingOffer)
      );
    } else {
      // tslint:disable-next-line:max-line-length
      return (
        !matchingOffer.send_mail_worker &&
        !matchingOffer.approved_worker &&
        !matchingOffer.rejected_worker &&
        this.isOffered(matchingOffer)
      );
    }
  }
  // BP回答待ち
  isChecking(matchingOffer: MatchingOffer, mailType: string): boolean {
    if (this.isProjectSide(mailType)) {
      return (
        matchingOffer.send_mail_project &&
        !matchingOffer.approved_project &&
        !matchingOffer.rejected_project
      );
    } else {
      return (
        matchingOffer.send_mail_worker &&
        !matchingOffer.approved_worker &&
        !matchingOffer.rejected_worker
      );
    }
  }
  // 承諾
  isApproved(matchingOffer: MatchingOffer, mailType: string): boolean {
    if (
      !this.isInterviewing(matchingOffer) &&
      !this.isSucceeded(matchingOffer)
    ) {
      if (this.isProjectSide(mailType)) {
        return matchingOffer.approved_project;
      } else {
        return matchingOffer.approved_worker;
      }
    }
  }
  // 辞退
  isRejected(matchingOffer: MatchingOffer, mailType: string): boolean {
    if (this.isProjectSide(mailType)) {
      return matchingOffer.rejected_project;
    } else {
      return matchingOffer.rejected_worker;
    }
  }
  // 面談中
  isInterviewing(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status === 'interviewing';
  }
  // 成立
  isSucceeded(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status === 'succeeded';
  }
  // 不成立
  isFailed(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status === 'failed';
  }

  // キャンセル
  isCancelled(matchingOffer: MatchingOffer): boolean {
    return matchingOffer.progress_status === 'cancelled';
  }

  // ステータス変更不能
  isUnchangeable(matchingOffer: MatchingOffer): boolean {
    return (
      this.isSucceeded(matchingOffer) ||
      this.isFailed(matchingOffer) ||
      this.isCancelled(matchingOffer)
    );
  }

  // 複数提案中
  isDubbleOffered(matchingOffer: MatchingOffer, mailType: string): boolean {
    if (this.isProjectSide(mailType)) {
      return matchingOffer.double_offered_project;
    } else {
      return matchingOffer.double_offered_worker;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from './http-client-abstract';
import { interval, Observable, Subject } from 'rxjs';
import {
  MailRegister,
  MailRegisterQmatchStatus
} from '../model/mail-register.model';
import { Smtp } from '../model/smtp.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailRegisterService extends HttpClientAbstract {
  // NOTE: MailRegisterService内でsmtp_setting, smtp_setting_id を持たないようにもできそう
  smtp_setting: Smtp;
  smtp_setting_id = -1;
  qmatchStatusCheckIds: Set<number> = new Set();
  qmatchStatusCheckSubject = new Subject<MailRegisterQmatchStatus[]>();
  qmatchStatusCheckObservable = this.qmatchStatusCheckSubject.asObservable();
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
    this.getSmtp().subscribe();

    // 案件人材管理のqmatch処理status更新バックグラウンドジョブ，３分ごと実行する
    interval(5 * 60 * 1000).subscribe(() => {
      if (this.qmatchStatusCheckIds.size > 0) {
        this.getQmatchSendStatuses(
          Array.from(this.qmatchStatusCheckIds.values())
        ).subscribe(statuses => {
          this.qmatchStatusCheckSubject.next(statuses);
        });
      }
    });
  }

  public getMailRegisters(step: number): Observable<any> {
    return super.requestGet('api/v1/mail_registers', { step });
  }

  public getRegisteredMailId(id: number): Observable<any> {
    return super.requestGet(
      'api/v1/mail_registers/' + id + '/registered_mail_id'
    );
  }

  public getSendStatus(
    registerId: number,
    type: 'qmatch_send_status' | 'send_status' = 'qmatch_send_status'
  ): Observable<MailRegisterQmatchStatus> {
    return super
      .requestGet(`api/v1/mail_registers/${registerId}/${type}`)
      .pipe(map(res => res.body));
  }

  public addQmatchSendStatusCheckId(id: number) {
    this.qmatchStatusCheckIds.add(id);
  }
  public removeQmatchSendStatusCheckId(id: number) {
    this.qmatchStatusCheckIds.delete(id);
  }
  public getQmatchSendStatuses(
    registerIds: number[]
  ): Observable<MailRegisterQmatchStatus[]> {
    return super
      .requestPost('api/v1/mail_registers/qmatch_send_statuses', {
        register_ids: registerIds
      })
      .pipe(map(res => res.body));
  }

  public updateSendConfig(registerId: number, params): Observable<any> {
    params['register_id'] = registerId;
    return super.requestPost(
      `api/v1/mail_registers/update_send_config`,
      params
    );
  }

  public updateMailRegister(params): Observable<any> {
    return super.requestPost('api/v1/mail_register/update', params);
  }

  public deleteMailRegisters(registerIds: number[]): Observable<any> {
    const params = { register_ids: registerIds };
    return super.requestPost('api/v1/mail_registers/destroy', params);
  }

  public searchFocusMail(): Observable<any> {
    return super.requestGet('api/v1/user_focus_mails');
  }

  public createOrDeleteFocusMail(focus_info): Observable<any> {
    return super.requestPost('api/v1/user_focus_mails', focus_info);
  }

  public register(params): Observable<any> {
    return super.requestPost('api/v1/mail_registers', params);
  }
  public sendToRegister(
    mail_id: number,
    mail_type: 'project' | 'worker'
  ): Observable<any> {
    const params = { matching_mail_id: mail_id, mail_type: mail_type };
    return super.requestPost(
      'api/v1/mail_register/create_from_matching_mail',
      params
    );
  }

  public goToRegisteredMailPage(mailRegister: MailRegister): void {
    if (mailRegister.mail_type === 'project') {
      window.open(
        '/matching/projects/' + mailRegister.registered_mail_id,
        '_blank'
      );
    } else {
      window.open(
        '/matching/workers/' + mailRegister.registered_mail_id,
        '_blank'
      );
    }
  }

  public parseAddressCSV(files: any): Observable<object[]> {
    const params = { files: files };

    return super
      .requestPostForm('api/v1/mail_registers/parse_address_csv', files)
      .pipe(map(res => res.body));
  }
  public textSearch(text: string, step: number): Observable<any> {
    const params = { text, step };

    return super
      .requestPost('api/v1/mail_registers/text_search', params)
      .pipe(map(res => res.body));
  }

  // smtp_settings
  public setSmtp(params: Smtp): Observable<any> {
    return this.requestPost('api/v1/smtp_settings', params).pipe(
      map(res => {
        this.smtp_setting_id = res.body.id;
        this.smtp_setting = res.body;
      })
    );
  }
  public getSmtp(): Observable<any> {
    return this.requestGet('api/v1/smtp_settings').pipe(
      map(res => {
        this.smtp_setting_id = res.body.id || -1;
        this.smtp_setting = res.body;
      })
    );
  }
  public testSmtp(test_email: string): Observable<any> {
    const id = this.smtp_setting_id;
    return this.requestPost('api/v1/smtp_settings/test', {
      id,
      test_email
    });
  }
  public smtpTestStatus(): Observable<any> {
    const id = this.smtp_setting_id;
    return this.requestPost('api/v1/smtp_settings/test_status', { id }).pipe(
      map(res => res.body.test_status)
    );
  }

  // メールCSVアップロード
  public uploadMailCSV(file: object): Observable<any> {
    return this.requestPostForm('api/v1/mail_register/create_from_csv', file);
  }

  checkOpenToCloud(): Observable<any> {
    return this.requestGet('api/v1/mail_registers/check_open_to_cloud');
  }
}

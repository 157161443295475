import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { UserRegistrationService } from '../../shared/service/user-registration.service';
import { CompanyService } from '../../shared/service/company.service';
import { ToastrService } from 'ngx-toastr';
import { SyncToStgService } from 'src/app/shared/service/sync-to-stg.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  companies: any;
  companies_stg: any;
  needSync: boolean;
  isSTG: boolean;

  freezeUser = new FormControl(-1);
  company_selected = '';
  userList = [];
  // setup form
  // one company can have multiple users(FormArray)
  newUserForm = this.fb.group({
    company_id: ['', Validators.required],
    company_stg_id: ['0', Validators.required],

    company_detail: this.fb.group({
      name: [''],
      company_code: [''],
      mail_domain: [''],
      representative_mail_account: ['']
    }),

    users: this.fb.array([this.NewUser()])
  });

  get users() {
    return this.newUserForm.get('users') as FormArray;
  }
  get company_id() {
    return this.newUserForm.get('company_id') as FormControl;
  }

  get company_detail() {
    return this.newUserForm.get('company_detail') as FormGroup;
  }

  get company_stg_id() {
    return this.newUserForm.get('company_stg_id') as FormControl;
  }

  ngOnInit() {
    this.isSTG = this.syncToStgService.needSync();
    this.needSync = this.syncToStgService.needSync();
    this.getCompanyList().then(() => {
      this.loadUsers();
      this.company_id.valueChanges.subscribe(val => this.companySelectChange());
    }).catch(err => {
      console.error('Error loading company list', err);
    });
  }

  constructor(
    private fb: FormBuilder,
    private userRegistrationService: UserRegistrationService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private syncToStgService: SyncToStgService
  ) {}

  NewUser() {
    return this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      user_code: ['', Validators.required],
      auth: [2, Validators.required],
      password: ['Abcd1234', Validators.required],
      password_confirm: ['Abcd1234', Validators.required],
      gross_margin_setting: ['5', Validators.required]
    });
  }

  getCompanyList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.companyService.getCompanyList().subscribe({
        next: res => {
          this.companies = res.body;
          this.companies.unshift({ company_code: 'not exist', id: '-1' });
          this.company_id.setValue(this.companies[0].id);
          resolve();
        },
        error: err => {
          this.toastrService.warning("Can't get company list!");
          console.warn("Can't get company list!");
          console.warn(err);
          reject(err);
        }
      });

      if (this.isSTG && this.needSync) {
        this.syncToStgService.getCompanyList().subscribe({
          next: res => {
            this.companies_stg = res.body;
            this.companies_stg.unshift({ company_code: 'not exist', id: '-1' });
            this.company_stg_id.setValue(this.companies_stg[0].id);
            resolve();
          },
          error: err => {
            this.toastrService.warning("STG_Can't get company list!");
            console.warn("STG_Can't get company list!");
            console.warn(err);
            reject(err);
          }
        });
      }
    });
  }

  addNewUser() {
    this.users.push(this.NewUser());
  }
  deleteUser(num: number) {
    this.users.removeAt(num);
  }

  companySelectChange() {
    const companySelected = this.companies.find(
      company => String(company.id) === this.company_id.value
    );
    if (companySelected) {
      console.log(`companySelected.company_code => ${companySelected.company_code}`);
      const companyStgSelected = this.companies_stg.find(
        company_stg => company_stg.company_code === companySelected.company_code
      );
      console.log(`companyStgSelected.id => ${companyStgSelected.id}`);
      if (companyStgSelected) {
        console.log(`companyStgSelected true`);
        this.company_stg_id.setValue(companyStgSelected.id);
      } else {
        this.company_stg_id.setValue('-1');
        console.log(`companyStgSelected false`);
      }
    }
  }

  onSubmit() {
    this.userRegistrationService.createNewUsers(this.newUserForm.value);
    if (this.isSTG && this.needSync) {
      this.userRegistrationService.createNewUsers(
        this.newUserForm.value,
        '_STG'
      );
    }
  }
  loadUsers() {
    this.userRegistrationService.getUserList().subscribe(res => {
      this.userList = res.body;
      console.log(this.userList);
    });
  }
  freeze() {
    this.userRegistrationService
      .freezeUser(this.freezeUser.value)
      .subscribe(res => {
        this.userList = res.body;
        this.freezeUser.reset();
        this.toastrService.success('freeze switch success');
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { Observable, ReplaySubject } from 'rxjs';
import { AdminMessage } from 'src/app/shared/model/admin-message.model';
import { map, tap } from 'rxjs/operators';
import { NewMailsIds } from '../model/new-received-mails';
@Injectable({
  providedIn: 'root'
})

// heart beat 情報を取得用のAPI，一つのリクエストにまとめる
export class AdminMessageService extends HttpClientAbstract {
  messages$ = new ReplaySubject<{
    admin_messages: { messages: AdminMessage[]; already_read_ids: number[] };
    new_received_count: any;
  }>(1);
  newReceivedCount$ = this.messages$.pipe(map(res => res.new_received_count));
  adminMessages$ = this.messages$.pipe(map(res => res.admin_messages));
  constructor(header: HttpHeaderService, http: HttpClient) {
    super(header, http);
  }
  public updateMessages(newMailsIds?: NewMailsIds) {
    const params = { admin_message: true };
    if (newMailsIds) {
      Object.assign(params, {
        new_received_count: {
          current_index: newMailsIds
        }
      });
    }
    this.requestPost('api/v1/admin_messages', params).subscribe(res =>
      this.messages$.next(res.body)
    );
  }

  public createNewMessage(data): Observable<any> {
    return this.requestPost('api/v1/admin_messages/new', data);
  }
  public deleteMessage(id: number): Observable<any> {
    return this.requestDelete(`api/v1/admin_messages/${id}`);
  }

  public messageChecked(messageIds: number[]) {
    return this.requestPost('api/v1/admin_messages/checked', {
      message_ids: messageIds
    }).pipe(tap(_ => this.updateMessages()));
  }
}

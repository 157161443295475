var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpHeaderService } from './http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from './http-client-abstract';
import { BehaviorSubject } from 'rxjs';
import { MailCustomTag } from '../model/mail-custom-tag.model';
import { map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
var MailCustomTagService = /** @class */ (function (_super) {
    __extends(MailCustomTagService, _super);
    function MailCustomTagService(header, http, toastSerivce) {
        var _this = _super.call(this, header, http) || this;
        _this.toastSerivce = toastSerivce;
        _this.allTags$ = new BehaviorSubject([]);
        _this.getTags();
        return _this;
    }
    MailCustomTagService.prototype.getTags = function () {
        var _this = this;
        this.getCustomTags().subscribe(function (tags) { return _this.allTags$.next(tags); });
    };
    MailCustomTagService.prototype.getCustomTags = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/mail_custom_tags').pipe(map(function (res) {
            var tags = res.body.map(function (tag) { return new MailCustomTag(tag); });
            return tags;
        }));
    };
    MailCustomTagService.prototype.createTags = function (tagName) {
        var _this = this;
        return this.requestPost('api/v1/mail_custom_tags', {
            tag_name: tagName
        }).pipe(tap(function (_) { }, function (error) {
            switch (error.status) {
                case 409:
                    _this.toastSerivce.warning('タグは既に存在します');
                    break;
                default:
                    _this.toastSerivce.warning('タグの作成は失敗しました');
                    break;
            }
        }), map(function (res) { return new MailCustomTag(res.body); }));
    };
    MailCustomTagService.prototype.applyTags = function (tagId, mailId, mailType) {
        var params = { mail_custom_tag_id: tagId };
        var mailIdType = mailType === 'project' ? 'project_mail_id' : 'worker_mail_id';
        params[mailIdType] = mailId;
        return this.requestPost('api/v1/mail_taggings', {
            mail_tagging: params
        }).pipe(map(function (res) {
            return new MailCustomTag({
                id: res.body.mail_custom_tag_id,
                mail_tagging_id: res.body.id
            });
        }));
    };
    MailCustomTagService.prototype.deleteTagging = function (taggingId) {
        return this.requestDelete("api/v1/mail_taggings/" + taggingId);
    };
    MailCustomTagService.prototype.deleteTags = function (tagId) {
        var _this = this;
        return this.requestDelete("api/v1/mail_custom_tags/" + tagId).pipe(map(function (res) { return _this.getTags(); }));
    };
    MailCustomTagService.ngInjectableDef = i0.defineInjectable({ factory: function MailCustomTagService_Factory() { return new MailCustomTagService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.ToastrService)); }, token: MailCustomTagService, providedIn: "root" });
    return MailCustomTagService;
}(HttpClientAbstract));
export { MailCustomTagService };

import { SERVICE_URL } from 'src/environments/environment';
import { API_SERVER_URL } from 'src/environments/environment';
import { MailQuotesPipe } from '../pipe/mail-quotes.pipe';
import { LeaveOnlyImportantLinePipe } from '../pipe/leave-only-important-line.pipe';
import { ConfirmMailTemplateService } from './confirm-mail-template.service';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../pipe/mail-quotes.pipe";
import * as i2 from "../pipe/leave-only-important-line.pipe";
import * as i3 from "./confirm-mail-template.service";
var PrepareTextService = /** @class */ (function () {
    function PrepareTextService(mailQuotesPipe, leaveOnlyImportantLinePipe, confirmMailTemplateService) {
        this.mailQuotesPipe = mailQuotesPipe;
        this.leaveOnlyImportantLinePipe = leaveOnlyImportantLinePipe;
        this.confirmMailTemplateService = confirmMailTemplateService;
        this.bodySubject = new Subject();
    }
    // ユーザのメール定型文設定リクエストの取得後にメール本文を生成するため、戻り値はsubject
    PrepareTextService.prototype.setApproveMailText = function (res, offerType) {
        var _this = this;
        // ユーザのメール定型文設定の取得
        this.confirmMailTemplateService.getMailTemplate().subscribe(function (result) {
            var body = '';
            var mailTemplate = result.body.mail_template;
            var isMailTemplateActivated = result.body.is_activated;
            body = _this.confirmMailBody(res, offerType, mailTemplate, isMailTemplateActivated);
            _this.bodySubject.next(body);
        });
        return this.bodySubject;
    };
    PrepareTextService.prototype.confirmMailBody = function (res, offerType, mailTemplate, isMailTemplateActivated) {
        var body = '';
        var project_mail = res.body.project_mail;
        var worker_mail = res.body.worker_mail;
        var downloadUrlPrefix = API_SERVER_URL + 'download/file_request?key=';
        // 承諾・辞退入力画面URL
        var approve_confirmation_response_page_url = SERVICE_URL + "approve-confirmation-response?token=" + res.body.auth_info.token;
        var base_mail = worker_mail;
        var other_mail = project_mail;
        if (offerType === 'worker') {
            base_mail = project_mail;
            other_mail = worker_mail;
        }
        if (isMailTemplateActivated) {
            body = mailTemplate;
        }
        else {
            body = this.defaultOfferTemplate();
        }
        body += '\n\n■ 回答用URL\n';
        body += approve_confirmation_response_page_url;
        body += '\n\n\n';
        body += "\u25A0 " + (offerType === 'worker' ? '提案案件' : '紹介人材') + " ===================";
        body += '\n\n';
        if (base_mail.attached_files[0]) {
            if (base_mail.attached_files[0].name !== 'skill_sheets.zip') {
                // file_info_urlをキーにしてAPIサーバー経由でS3ファイルをダウンロードするURLを作成
                body += "\u30B9\u30AD\u30EB\u30B7\u30FC\u30C8: " + downloadUrlPrefix + base_mail.attached_files[0].file_info_url;
            }
        }
        body += '\n\n';
        body += this.leaveOnlyImportantLinePipe.transform(base_mail.body, base_mail);
        body += '\n\n\nOriginal Mail\n';
        body += this.mailQuotesPipe.transform(other_mail.body);
        return body;
    };
    PrepareTextService.prototype.defaultOfferTemplate = function () {
        var body;
        body = 'お世話になっております。';
        body += '\nいただいた表題のメールに関しまして、以下の提案をさせていただきます。';
        body += '\nご検討のほど、どうぞよろしくお願いいたします。';
        body += '\n（以下のURLリンクをクリックいただくと、システム上でご回答を入力いただけます）';
        return body;
    };
    PrepareTextService.prototype.setReplyMailText = function (mailBody) {
        // ユーザのメール定型文設定の取得
        var body = '';
        body += '\n\n\nOriginal Mail\n';
        body += this.mailQuotesPipe.transform(mailBody);
        return body;
    };
    PrepareTextService.ngInjectableDef = i0.defineInjectable({ factory: function PrepareTextService_Factory() { return new PrepareTextService(i0.inject(i1.MailQuotesPipe), i0.inject(i2.LeaveOnlyImportantLinePipe), i0.inject(i3.ConfirmMailTemplateService)); }, token: PrepareTextService, providedIn: "root" });
    return PrepareTextService;
}());
export { PrepareTextService };

import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MailCustomTag } from '../../model/mail-custom-tag.model';
import { MailInfo } from '../../model/mail-info.model';
import { MailCustomTagService } from '../../service/mail-custom-tag.service';

import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-mail-custom-tag-register',
  templateUrl: './mail-custom-tag-register.component.html',
  styleUrls: ['./mail-custom-tag-register.component.scss']
})
export class MailCustomTagRegisterComponent implements OnInit {
  @Input() mailType: string;
  @Input() mailInfo: MailInfo;
  taggedIds: number[];
  mailId: number;
  newTagName = new FormControl('');
  allTags: MailCustomTag[];
  targetTag: MailCustomTag;
  tagSub: Subscription;
  confirmDeleteModal: NgbModalRef;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private mailCustomTagService: MailCustomTagService
  ) {}

  ngOnInit() {
    this.tagSub = this.mailCustomTagService.allTags$
      .asObservable()
      .subscribe(tags => (this.allTags = tags));

    // this.getTaggedIds();
    this.taggedIds = this.mailInfo.mail_custom_tags.map(tag => tag.id);
    this.mailId = this.mailInfo.id;
    this.mailCustomTagService.getTags();
  }
  selectTag(activated: boolean, tagId: number): void {
    if (!activated) {
      this.mailCustomTagService
        .applyTags(tagId, this.mailId, this.mailType)
        .subscribe(tag => {
          tag.tag_name = this.allTags.filter(t => t.id === tag.id)[0].tag_name;
          this.mailInfo.mail_custom_tags.push(tag);
          this.taggedIds.push(tag.id);
        });
    } else {
      const taggingId = this.mailInfo.mail_custom_tags.filter(
        tag => tag.id === tagId
      )[0].mail_tagging_id;

      this.mailCustomTagService.deleteTagging(taggingId).subscribe(_ => {
        this.mailInfo.mail_custom_tags = this.mailInfo.mail_custom_tags.filter(
          tag => tag.id !== tagId
        );
        this.taggedIds = this.taggedIds.filter(id => id !== tagId);
      });
    }
  }

  createNewTag(): void {
    this.mailCustomTagService
      .createTags(this.newTagName.value)
      .subscribe(res => {
        this.mailCustomTagService.getTags();
        this.newTagName.reset();
      });
  }

  openConfirmDeleteModal(tag: MailCustomTag, content: TemplateRef<any>) {
    this.targetTag = tag;
    this.confirmDeleteModal = this.modalService.open(content, { centered: true });
  }

  // 緑タグ 削除
  deleteTag(): void {
    this.mailCustomTagService.deleteTags(this.targetTag.id).subscribe(_ => {
      this.confirmDeleteModal.dismiss();
      this.mailInfo.mail_custom_tags = this.mailInfo.mail_custom_tags.filter(
        tag => tag.id !== this.targetTag.id
      );
    });
  }
}

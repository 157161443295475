// Module
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from '../../../node_modules/ngx-pagination';
// Component
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { SearchComponent } from './component/search-section/search/search.component';
// Service
import { AccountService } from 'src/app/shared/service/account.service';
import { StringService } from 'src/app/shared/service/string.service';
import { SessionService } from 'src/app/shared/service/session.service';
import { SearchProjectService } from 'src/app/shared/service/search/search-project.service';
import { SearchProjectWorkerService } from 'src/app/shared/service/search/search-project-worker.service';
import { SearchWorkerService } from 'src/app/shared/service/search/search-worker.service';
import { SearchWorkerProjectService } from 'src/app/shared/service/search/search-worker-project.service';
import { RegExpService } from './service/regexp.service';
import { PaginationComponent } from './component/pagination/pagination.component';
import { PagerComponent } from './component/pager/pager.component';
import { MatchingButtonComponent } from './component/matching-button/matching-button.component';
import { MatchingLabelComponent } from './component/mail-card/card-offer-page-header/matching-label/matching-label.component';
import { ConfirmationRequestComponent } from './modal/confirmation-request/confirmation-request.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LeaveOnlyImportantLinePipe } from './pipe/leave-only-important-line.pipe';
import { CountReceivedTimesPipe } from './pipe/count-received-times.pipe';
import { CheckOutsideOfJapanPipe } from './pipe/check-outside-of-japan.pipe';
import { ModifyWorkImmidiatelyTextPipe } from './pipe/modify-work-immidiately-text.pipe';
import { ModifyNumberOfConnectCompanyForWorkerPipe } from './pipe/modify-number-of-connect-company-for-worker.pipe';
import { ModifyNumberOfConnectCompanyForProjectPipe } from './pipe/modify-number-of-connect-company-for-project.pipe';
import { ModifyEmploymentStatusTextForWorkerPipe } from './pipe/modify-employment-status-text-for-worker.pipe';
import { ModifyEmploymentStatusTextForProjectPipe } from './pipe/modify-employment-status-text-for-project.pipe';
import { TranslateCountryOfCitizenshipTextPipe } from './pipe/translate-country-of-citizenship-text.pipe';
import { ModifyInterviewCountTextPipe } from './pipe/modify-interview-count-text.pipe';
import { FeePipe } from '../core/pipe/fee.pipe';
import { AgePipe } from '../core/pipe/age.pipe';
import { ReloadButtonComponent } from './component/reload-button/reload-button.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { NotificationBadgePipe } from './pipe/notification-badge.pipe';
import { OfferButtonComponent } from './component/offer-button/offer-button.component';
import { ModifyNewArrivalMailNumberPipe } from './pipe/modify-new-arrival-mail-number.pipe';
import { PositionPipe } from './pipe/position.pipe';
import { ErrorFeedbackComponent } from './modal/error-feedback/error-feedback.component';
import { MatchingScorePipe } from './pipe/matching-score.pipe';
import { OfferFilterComponent } from './component/offer-filter/offer-filter.component';
import { FileDownloadButtonComponent } from './component/file-download-button/file-download-button.component';
import { SkillSheetButtonComponent } from './component/skill-sheet-button/skill-sheet-button.component';
import { MailQuotesPipe } from './pipe/mail-quotes.pipe';
import { ChangeStringToHyperLinkPipe } from './pipe/change-string-to-hyper-link.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainPartButtonComponent } from './component/main-part-button/main-part-button.component';
import { ErrorFeedBackButtonComponent } from './component/error-feed-back-button/error-feed-back-button.component';
import { EditMailInfoButtonComponent } from './component/edit-mail-info-button/edit-mail-info-button.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { UserFocusStarComponent } from './component/user-focus-star/user-focus-star.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RankPipe } from './pipe/rank.pipe';
import { SetBodyStringPipe } from './pipe/set-body-string.pipe';
import { FilterColumnPipe } from './pipe/filter-column.pipe';
import { EnglishKeywordsPipe } from './pipe/english-keywords.pipe';
import { OtherCandidateButtonComponent } from './component/other-candidate-button/other-candidate-button.component';
import { DeEmphasizeNonMainpartPipe } from './pipe/de-emphasize-non-mainpart.pipe';
import { LocationService } from './service/location.service';
import { ReactiveFormsModule } from '@angular/forms';
import { OfferingTagComponent } from './component/mail-card/tag-area/offering-tag/offering-tag.component';
import { OfferActionUserComponent } from './component/offer-action-user/offer-action-user.component';
import { FilterSwitchAnnotationComponent } from './component/filter-switch-annotation/filter-switch-annotation.component';
import { BpInputComponent } from './component/bp-input/bp-input.component';
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import { TreeModule } from 'primeng/tree';
import { SearchTagRegisterComponent } from './component/search-tag-register/search-tag-register.component';
import { SearchTagListComponent } from './component/search-tag-list/search-tag-list.component';
import { SearchTagComponent } from './component/search-tag/search-tag.component';
import { ResizableModule } from 'angular-resizable-element';
import { ModifyMailTypePipe } from './pipe/modify-mail-type.pipe';
import { ModifyCompanyPipe } from './pipe/modify-company.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from 'src/app/shared/interceptor/authorization.interceptor';
import { ThemeSwitcherComponent } from './component/theme-switcher/theme-switcher.component';
import { CalcFilterFinishedAnotationComponent } from './component/calc-filter-finished-anotation/calc-filter-finished-anotation.component';
import { ManualOfferComponent } from './component/manual-offer/manual-offer.component';
import { AutoMatchingSearchSectionComponent } from './component/search-section/auto-matching-search-section/auto-matching-search-section.component';
import { HighlightSearchedKeywordPipe } from './pipe/highlight-searched-keyword.pipe';
import { MailerComponent } from './modal/component/mailer/mailer.component';
import { DetailEmailReplyComponent } from './modal/detail-email-reply/detail-email-reply.component';
import { MemoComponent } from './component/memo/memo.component';
import { TranslateSearchConditionPipe } from './pipe/translate-search-condition.pipe';
import { CardComponent } from './component/mail-card/card/card.component';
import { CardActionAreaComponent } from './component/mail-card/card-action-area/card-action-area.component';
import { CardDetailInfoComponent } from './component/mail-card/card-detail-info/card-detail-info.component';
import { CardHeaderTitleComponent } from './component/mail-card/card-header-title/card-header-title.component';
import { CardHeaderInfoComponent } from './component/mail-card/card-header-info/card-header-info.component';
import { CardSkillsheetInfoComponent } from './component/mail-card/card-skillsheet-info/card-skillsheet-info.component';
import { CardOfferPageHeaderComponent } from './component/mail-card/card-offer-page-header/card-offer-page-header.component';
import { TagAreaComponent } from './component/mail-card/tag-area/tag-area.component';
import { SkillListTagComponent } from './component/mail-card/tag-area/skill-list-tag/skill-list-tag.component';
import { SafePipe } from './pipe/safe.pipe';
import { SearchActionComponent } from './component/search-section/search-action/search-action.component';
import { SearchItemsComponent } from './component/search-section/search-items/search-items.component';
import { MailCustomTagRegisterComponent } from './modal/mail-custom-tag-register/mail-custom-tag-register.component';
import { TagButtonComponent } from './component/tag-button/tag-button.component';
import { CheckBoxComponent } from './component/check-box/check-box.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { PairSuitabilityFeedbackButtonComponent } from './component/pair-suitability-feedback-button/pair-suitability-feedback-button.component';
import { SearchSectionToggleComponent } from './component/search-section-toggle/search-section-toggle.component';
import { SortButtonComponent } from './component/sort-button/sort-button.component';
import { ItemBaseComponent } from './component/search-section/search-items/item-base/item-base.component';
import { FeeComponent } from './component/search-section/search-items/items/fee/fee.component';
import { GrossMarginComponent } from './component/search-section/search-items/items/gross-margin/gross-margin.component';
import { AgeComponent } from './component/search-section/search-items/items/age/age.component';
import { LocationComponent } from './component/search-section/search-items/items/location/location.component';
import { CommuteComponent } from './component/search-section/search-items/items/commute/commute.component';
import { MailFromComponent } from './component/search-section/search-items/items/mail-from/mail-from.component';
import { BpRankComponent } from './component/search-section/search-items/items/bp-rank/bp-rank.component';
import { NumberOfConnectCompanyComponent } from './component/search-section/search-items/items/number-of-connect-company/number-of-connect-company.component';
import { EmploymentStatusComponent } from './component/search-section/search-items/items/employment-status/employment-status.component';
import { WorkImmidiatelyComponent } from './component/search-section/search-items/items/work-immidiately/work-immidiately.component';
import { InterviewTimesComponent } from './component/search-section/search-items/items/interview-times/interview-times.component';
import { ReceiptDatetimeComponent } from './component/search-section/search-items/items/receipt-datetime/receipt-datetime.component';
import { CountryOfCitizenshipComponent } from './component/search-section/search-items/items/country-of-citizenship/country-of-citizenship.component';
import { KeywordComponent } from './component/search-section/search-items/items/keyword/keyword.component';
import { RequiredSkillsComponent } from './component/search-section/search-items/items/required-skills/required-skills.component';
import { BeginAtComponent } from './component/search-section/search-items/items/begin-at/begin-at.component';
import { SetTitleStringPipe } from './pipe/set-title-string.pipe';
import { NotificationRegisterComponent } from './component/notification-register/notification-register.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalenderCustomHeaderComponent } from './component/date-picker/calender-custom-header/calender-custom-header.component';
import { OpenToServiceToggleComponent } from './component/open-to-service-toggle/open-to-service-toggle.component';
import { ConfirmOpenToServiceComponent } from './modal/confirm-open-to-service/confirm-open-to-service.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule.forRoot(),
    NgxPaginationModule,
    ClipboardModule,
    Ng2CompleterModule,
    InfiniteScrollModule,
    ContextMenuModule.forRoot(),
    DragDropModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    TreeModule,
    ResizableModule,
    MatDatepickerModule,
  ],
  exports: [
    BreadcrumbComponent,
    FormsModule,
    Ng2CompleterModule,
    MatchingButtonComponent,
    MatchingLabelComponent,
    PaginationComponent,
    PagerComponent,
    SearchComponent,
    LeaveOnlyImportantLinePipe,
    CountReceivedTimesPipe,
    CheckOutsideOfJapanPipe,
    ModifyWorkImmidiatelyTextPipe,
    ModifyNumberOfConnectCompanyForWorkerPipe,
    ModifyNumberOfConnectCompanyForProjectPipe,
    ModifyEmploymentStatusTextForWorkerPipe,
    ModifyEmploymentStatusTextForProjectPipe,
    TranslateCountryOfCitizenshipTextPipe,
    ModifyInterviewCountTextPipe,
    FeePipe,
    AgePipe,
    ReloadButtonComponent,
    NotificationBadgePipe,
    OfferButtonComponent,
    ModifyNewArrivalMailNumberPipe,
    PositionPipe,
    MatchingScorePipe,
    OfferFilterComponent,
    FileDownloadButtonComponent,
    SkillSheetButtonComponent,
    MailQuotesPipe,
    ChangeStringToHyperLinkPipe,
    InfiniteScrollModule,
    MainPartButtonComponent,
    ErrorFeedBackButtonComponent,
    EditMailInfoButtonComponent,
    ContextMenuModule,
    UserFocusStarComponent,
    DragDropModule,
    RankPipe,
    NgbModule,
    SetBodyStringPipe,
    FilterColumnPipe,
    EnglishKeywordsPipe,
    OtherCandidateButtonComponent,
    DeEmphasizeNonMainpartPipe,
    ReactiveFormsModule,
    OfferingTagComponent,
    OfferActionUserComponent,
    FilterSwitchAnnotationComponent,
    BpInputComponent,
    SearchTagRegisterComponent,
    SearchTagListComponent,
    SearchTagComponent,
    ModifyMailTypePipe,
    ModifyCompanyPipe,
    ThemeSwitcherComponent,
    CalcFilterFinishedAnotationComponent,
    AutoMatchingSearchSectionComponent,
    HighlightSearchedKeywordPipe,
    DetailEmailReplyComponent,
    MemoComponent,
    TranslateSearchConditionPipe,
    CardComponent,
    CardActionAreaComponent,
    CardDetailInfoComponent,
    CardHeaderTitleComponent,
    CardHeaderInfoComponent,
    CardSkillsheetInfoComponent,
    CardOfferPageHeaderComponent,
    TagAreaComponent,
    SkillListTagComponent,
    TagButtonComponent,
    CheckBoxComponent,
    DatePickerComponent,
    SearchSectionToggleComponent,
    SortButtonComponent,
    SearchItemsComponent,
    MatDatepickerModule,
    MatInputModule,
    OpenToServiceToggleComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    AccountService,
    StringService,
    SearchProjectService,
    SearchProjectWorkerService,
    SearchWorkerService,
    SearchWorkerProjectService,
    RegExpService,
    SessionService,
    SetBodyStringPipe,
    LocationService,
    DatePipe
  ],
  declarations: [
    SearchComponent,
    BreadcrumbComponent,
    PaginationComponent,
    PagerComponent,
    MatchingButtonComponent,
    MatchingLabelComponent,
    ConfirmationRequestComponent,
    LeaveOnlyImportantLinePipe,
    CountReceivedTimesPipe,
    CheckOutsideOfJapanPipe,
    ModifyWorkImmidiatelyTextPipe,
    ModifyNumberOfConnectCompanyForWorkerPipe,
    ModifyNumberOfConnectCompanyForProjectPipe,
    ModifyEmploymentStatusTextForWorkerPipe,
    ModifyEmploymentStatusTextForProjectPipe,
    TranslateCountryOfCitizenshipTextPipe,
    ModifyInterviewCountTextPipe,
    FeePipe,
    AgePipe,
    ReloadButtonComponent,
    NotificationBadgePipe,
    OfferButtonComponent,
    ModifyNewArrivalMailNumberPipe,
    PositionPipe,
    ErrorFeedbackComponent,
    MatchingScorePipe,
    OfferFilterComponent,
    FileDownloadButtonComponent,
    SkillSheetButtonComponent,
    MailQuotesPipe,
    ChangeStringToHyperLinkPipe,
    MainPartButtonComponent,
    ErrorFeedBackButtonComponent,
    EditMailInfoButtonComponent,
    UserFocusStarComponent,
    RankPipe,
    SetBodyStringPipe,
    FilterColumnPipe,
    EnglishKeywordsPipe,
    OtherCandidateButtonComponent,
    DeEmphasizeNonMainpartPipe,
    OfferingTagComponent,
    OfferActionUserComponent,
    FilterSwitchAnnotationComponent,
    BpInputComponent,
    SearchTagRegisterComponent,
    SearchTagListComponent,
    SearchTagComponent,
    ModifyMailTypePipe,
    ModifyCompanyPipe,
    ThemeSwitcherComponent,
    CalcFilterFinishedAnotationComponent,
    ManualOfferComponent,
    AutoMatchingSearchSectionComponent,
    HighlightSearchedKeywordPipe,
    MailerComponent,
    DetailEmailReplyComponent,
    MemoComponent,
    TranslateSearchConditionPipe,
    CardComponent,
    CardActionAreaComponent,
    CardDetailInfoComponent,
    CardHeaderTitleComponent,
    CardHeaderInfoComponent,
    CardSkillsheetInfoComponent,
    CardOfferPageHeaderComponent,
    TagAreaComponent,
    SkillListTagComponent,
    SafePipe,
    SearchActionComponent,
    SearchItemsComponent,
    MailCustomTagRegisterComponent,
    TagButtonComponent,
    CheckBoxComponent,
    DatePickerComponent,
    PairSuitabilityFeedbackButtonComponent,
    SearchSectionToggleComponent,
    SortButtonComponent,
    ItemBaseComponent,
    FeeComponent,
    GrossMarginComponent,
    AgeComponent,
    LocationComponent,
    CommuteComponent,
    MailFromComponent,
    BpRankComponent,
    NumberOfConnectCompanyComponent,
    EmploymentStatusComponent,
    WorkImmidiatelyComponent,
    InterviewTimesComponent,
    ReceiptDatetimeComponent,
    CountryOfCitizenshipComponent,
    KeywordComponent,
    RequiredSkillsComponent,
    BeginAtComponent,
    SetTitleStringPipe,
    NotificationRegisterComponent,
    CalenderCustomHeaderComponent,
    OpenToServiceToggleComponent,
    ConfirmOpenToServiceComponent
  ],
  entryComponents: [
    ConfirmationRequestComponent,
    ErrorFeedbackComponent,
    MailerComponent,
    MailCustomTagRegisterComponent,
    CalenderCustomHeaderComponent,
    ConfirmOpenToServiceComponent
  ]
})
export class SharedModule {}

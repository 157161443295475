import { Component } from '@angular/core';
import { FileDownloadService } from 'src/app/shared/service/file-download.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent {

  constructor(
    private fileDownloadService: FileDownloadService,
    private toastrService: ToastrService
  ) {
    this.getCSV()
  }

  // CSVダウンロードする
  getCSV() {
    const key = new URLSearchParams(window.location.search).get('key');
    this.fileDownloadService.requestDownloadCSV({key}).subscribe({
      next: res => {
        const blob = res.body;
        const fname = res.headers.get('Content-Disposition').match(/filename="([^"]+)"/)[1] || 'download.csv';
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = decodeURI(fname);
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error: error => {
        if (error.status === 404) {
          this.toastrService.error('ダウンロード中にエラーが発生しました。エラーが持続する場合はサポートにご連絡ください');
        } else {
          this.toastrService.error('一時的な通信障害によりダウンロードに失敗しました。お手数ですが再度ボタンを押してください');
        }
      }
    });
  }
}

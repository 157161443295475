var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { API_SERVER_URL as STG_API_URL } from 'src/environments/environment.stg';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
import * as i4 from "./account.service";
var SyncToStgService = /** @class */ (function () {
    function SyncToStgService(header, http, toastrService, accountService) {
        this.header = header;
        this.http = http;
        this.toastrService = toastrService;
        this.accountService = accountService;
    }
    SyncToStgService.prototype.requestGet_STG = function (requestDestination, params) {
        var url = STG_API_URL + requestDestination;
        return this.http.get(url, this.header.getSTGHttpOptions(params));
    };
    SyncToStgService.prototype.requestPost_STG = function (requestDestination, params) {
        var url = STG_API_URL + requestDestination;
        return this.http.post(url, params, this.header.getSTGHttpOptions());
    };
    SyncToStgService.prototype.signIn = function (loginForm) {
        var _this = this;
        var param = __assign({}, loginForm, { sync_to_stg: true });
        if (this.needSync()) {
            this.requestPost_STG('api/v1/auth/sign_in', param).subscribe(function (res) {
                // Headerの情報を取得、sessionにトークン設定
                _this.accountService.setSTGAccount(res);
                _this.toastrService.success('Syncログインに成功しました');
            }, function (error) {
                _this.toastrService.error('Syncログインに失敗しました');
            });
        }
    };
    SyncToStgService.prototype.getCompanyList = function () {
        return this.requestGet_STG('api/v1/company_list');
    };
    SyncToStgService.prototype.getCompany = function (id) {
        return this.requestGet_STG('api/v1/company', { id: id });
    };
    SyncToStgService.prototype.createNewCompany = function (data) {
        return this.requestPost_STG('api/v1/company', data);
    };
    SyncToStgService.prototype.checkSyncSignIn = function () {
        return localStorage.sync_to_stg_account ? true : false;
    };
    SyncToStgService.prototype.needSync = function () {
        return environment.production && !environment.staging;
    };
    SyncToStgService.ngInjectableDef = i0.defineInjectable({ factory: function SyncToStgService_Factory() { return new SyncToStgService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.ToastrService), i0.inject(i4.AccountService)); }, token: SyncToStgService, providedIn: "root" });
    return SyncToStgService;
}());
export { SyncToStgService };

import { Injectable } from '@angular/core';
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/shared/service/company.service';
import { SyncToStgService } from 'src/app/shared/service/sync-to-stg.service';
@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService extends HttpClientAbstract {
  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    private toastrService: ToastrService,
    private companyService: CompanyService,
    private syncToStgService: SyncToStgService
  ) {
    super(header, http);
  }

  public createNewUsers(data: any, server: string = '') {
    console.log(JSON.stringify(data));

    // unpack form data
    let { company_id, company_stg_id, company_detail, users } = data;

    company_id = server === '_STG' ? company_stg_id : company_id;

    if (company_id === '-1') {
      const service =
        server === '_STG' ? this.syncToStgService : this.companyService;

      service.createNewCompany(company_detail).subscribe({
        next: new_company => {
          company_id = new_company.body['id'];
          this.toastrService.success(server + '会社登録成功');
          this.newUsers(Number(company_id), users, server);
        },
        error: err => {
          this.toastrService.warning(server + '会社登録失敗');
          this.toastrService.warning(
            server + err['error']['errors']['full_messages'].join('\n')
          );
          console.warn(
            server + err['error']['errors']['full_messages'].join('\n')
          );
        }
      });
    } else {
      this.newUsers(Number(company_id), users, server);
    }
  }

  public newUsers(company: number, users: object[], server: string = '') {
    for (let user of users) {
      user['company_id'] = company;
      const post =
        server === '_STG'
          ? this.syncToStgService.requestPost_STG('api/v1/auth', user)
          : super.requestPost('api/v1/auth', user);

      post.subscribe({
        next: x => {
          this.toastrService.success(
            server + user['name'] + '   ユーザー登録成功'
          );
        },
        error: err => {
          this.toastrService.warning(
            server + user['name'] + '  ユーザー登録失敗'
          );
          console.warn(err);
          this.toastrService.warning(
            server + err['error']['errors']['full_messages'].join('\n')
          );
          console.warn(
            server + err['error']['errors']['full_messages'].join('\n')
          );
        }
      });
    }
  }

  getUserList() {
    return super.requestGet('api/v1/users');
  }
  freezeUser(id: number) {
    return super.requestPost('api/v1/users/freeze_user', { id: id });
  }
}

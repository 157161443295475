var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
var AdminMessageService = /** @class */ (function (_super) {
    __extends(AdminMessageService, _super);
    function AdminMessageService(header, http) {
        var _this = _super.call(this, header, http) || this;
        _this.messages$ = new ReplaySubject(1);
        _this.newReceivedCount$ = _this.messages$.pipe(map(function (res) { return res.new_received_count; }));
        _this.adminMessages$ = _this.messages$.pipe(map(function (res) { return res.admin_messages; }));
        return _this;
    }
    AdminMessageService.prototype.updateMessages = function (newMailsIds) {
        var _this = this;
        var params = { admin_message: true };
        if (newMailsIds) {
            Object.assign(params, {
                new_received_count: {
                    current_index: newMailsIds
                }
            });
        }
        this.requestPost('api/v1/admin_messages', params).subscribe(function (res) {
            return _this.messages$.next(res.body);
        });
    };
    AdminMessageService.prototype.createNewMessage = function (data) {
        return this.requestPost('api/v1/admin_messages/new', data);
    };
    AdminMessageService.prototype.deleteMessage = function (id) {
        return this.requestDelete("api/v1/admin_messages/" + id);
    };
    AdminMessageService.prototype.messageChecked = function (messageIds) {
        var _this = this;
        return this.requestPost('api/v1/admin_messages/checked', {
            message_ids: messageIds
        }).pipe(tap(function (_) { return _this.updateMessages(); }));
    };
    AdminMessageService.ngInjectableDef = i0.defineInjectable({ factory: function AdminMessageService_Factory() { return new AdminMessageService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient)); }, token: AdminMessageService, providedIn: "root" });
    return AdminMessageService;
}(HttpClientAbstract));
export { AdminMessageService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// model
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { SearchService } from 'src/app/shared/service/search/search.service';
import { SearchPage } from '../../model/search-page.model';
import { DetailPageInfo } from '../../model/deail-page-info.model';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
var SearchWorkerProjectService = /** @class */ (function (_super) {
    __extends(SearchWorkerProjectService, _super);
    function SearchWorkerProjectService(header, http, router) {
        var _this = _super.call(this, header, http) || this;
        _this.router = router;
        // サービス毎に初期値を設定する
        _this.initPage = { page: 1 };
        _this.initPerPage = { perPage: 10 };
        _this.selfServiceName = 'SearchWorkerProjectService';
        return _this;
    }
    SearchWorkerProjectService.prototype.doSearch = function (id, detailPageInfo) {
        this.setSearchPage(new SearchPage({ page: 1 }));
        detailPageInfo = new DetailPageInfo(detailPageInfo);
        return _super.prototype.requestPost.call(this, 'api/v1/workers/' + id + '/projects', this.getSearchParameters(detailPageInfo.getParams()));
    };
    SearchWorkerProjectService.prototype.showWorkerMail = function (id) {
        return _super.prototype.requestGet.call(this, 'api/v1/workers/' + id);
    };
    SearchWorkerProjectService.prototype.getTransitionPage = function (id, direction) {
        var params = { id: id, direction: direction };
        return _super.prototype.requestGet.call(this, 'api/v1/workers/page_transition', params);
    };
    SearchWorkerProjectService.prototype.transitionPage = function (id, direction) {
        var _this = this;
        var observable;
        if (sessionStorage.workerIds) {
            observable = new Observable(function (observer) {
                observer.next(_this.getNextmailIdFromSession(id, direction));
            });
        }
        else {
            observable = this.getTransitionPage(id, direction).pipe(map(function (res) { return res.body.worker.id; }));
        }
        observable.subscribe(function (mailId) {
            if (mailId) {
                _this.router.navigate(['/matching/workers/' + mailId]);
            }
        });
    };
    SearchWorkerProjectService.prototype.getNextmailIdFromSession = function (id, direction) {
        var nexttMailId;
        var workerIds = sessionStorage.workerIds.split(',');
        var indexOfCurrentMail = workerIds.indexOf(id);
        if (direction === 'next') {
            nexttMailId = workerIds[indexOfCurrentMail + 1];
        }
        else {
            nexttMailId = workerIds[indexOfCurrentMail - 1];
        }
        return nexttMailId;
    };
    SearchWorkerProjectService.ngInjectableDef = i0.defineInjectable({ factory: function SearchWorkerProjectService_Factory() { return new SearchWorkerProjectService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.Router)); }, token: SearchWorkerProjectService, providedIn: "root" });
    return SearchWorkerProjectService;
}(SearchService));
export { SearchWorkerProjectService };

import { Injectable } from '@angular/core';
import { HttpHeaderService } from './http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from './http-client-abstract';
import { Observable, BehaviorSubject } from 'rxjs';
import { MailCustomTag } from '../model/mail-custom-tag.model';
import { map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class MailCustomTagService extends HttpClientAbstract {
  allTags$ = new BehaviorSubject<MailCustomTag[]>([]);
  constructor(
    header: HttpHeaderService,
    http: HttpClient,
    private toastSerivce: ToastrService
  ) {
    super(header, http);
    this.getTags();
  }

  getTags(): void {
    this.getCustomTags().subscribe(tags => this.allTags$.next(tags));
  }

  getCustomTags(): Observable<MailCustomTag[]> {
    return super.requestGet('api/v1/mail_custom_tags').pipe(
      map(res => {
        const tags = res.body.map(tag => new MailCustomTag(tag));
        return tags;
      })
    );
  }

  createTags(tagName: string): Observable<MailCustomTag> {
    return this.requestPost('api/v1/mail_custom_tags', {
      tag_name: tagName
    }).pipe(
      tap(
        _ => {},
        error => {
          switch (error.status) {
            case 409:
              this.toastSerivce.warning('タグは既に存在します');
              break;

            default:
              this.toastSerivce.warning('タグの作成は失敗しました');
              break;
          }
        }
      ),
      map(res => new MailCustomTag(res.body))
    );
  }

  applyTags(
    tagId: number,
    mailId: number,
    mailType: string
  ): Observable<MailCustomTag> {
    const params = { mail_custom_tag_id: tagId };
    const mailIdType =
      mailType === 'project' ? 'project_mail_id' : 'worker_mail_id';
    params[mailIdType] = mailId;
    return this.requestPost('api/v1/mail_taggings', {
      mail_tagging: params
    }).pipe(
      map(
        res =>
          new MailCustomTag({
            id: res.body.mail_custom_tag_id,
            mail_tagging_id: res.body.id
          })
      )
    );
  }

  deleteTagging(taggingId: number): Observable<any> {
    return this.requestDelete(`api/v1/mail_taggings/${taggingId}`);
  }

  deleteTags(tagId: number): Observable<any> {
    return this.requestDelete(`api/v1/mail_custom_tags/${tagId}`).pipe(
      map(res => this.getTags())
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpHeaderService } from 'src/app/shared/service/http-header.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientAbstract } from 'src/app/shared/service/http-client-abstract';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/shared/service/company.service';
import { SyncToStgService } from 'src/app/shared/service/sync-to-stg.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-header.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
import * as i4 from "./company.service";
import * as i5 from "./sync-to-stg.service";
var UserRegistrationService = /** @class */ (function (_super) {
    __extends(UserRegistrationService, _super);
    function UserRegistrationService(header, http, toastrService, companyService, syncToStgService) {
        var _this = _super.call(this, header, http) || this;
        _this.toastrService = toastrService;
        _this.companyService = companyService;
        _this.syncToStgService = syncToStgService;
        return _this;
    }
    UserRegistrationService.prototype.createNewUsers = function (data, server) {
        var _this = this;
        if (server === void 0) { server = ''; }
        console.log(JSON.stringify(data));
        // unpack form data
        var company_id = data.company_id, company_stg_id = data.company_stg_id, company_detail = data.company_detail, users = data.users;
        company_id = server === '_STG' ? company_stg_id : company_id;
        if (company_id === '-1') {
            var service = server === '_STG' ? this.syncToStgService : this.companyService;
            service.createNewCompany(company_detail).subscribe({
                next: function (new_company) {
                    company_id = new_company.body['id'];
                    _this.toastrService.success(server + '会社登録成功');
                    _this.newUsers(Number(company_id), users, server);
                },
                error: function (err) {
                    _this.toastrService.warning(server + '会社登録失敗');
                    _this.toastrService.warning(server + err['error']['errors']['full_messages'].join('\n'));
                    console.warn(server + err['error']['errors']['full_messages'].join('\n'));
                }
            });
        }
        else {
            this.newUsers(Number(company_id), users, server);
        }
    };
    UserRegistrationService.prototype.newUsers = function (company, users, server) {
        var _this = this;
        if (server === void 0) { server = ''; }
        var _loop_1 = function (user) {
            user['company_id'] = company;
            var post = server === '_STG'
                ? this_1.syncToStgService.requestPost_STG('api/v1/auth', user)
                : _super.prototype.requestPost.call(this_1, 'api/v1/auth', user);
            post.subscribe({
                next: function (x) {
                    _this.toastrService.success(server + user['name'] + '   ユーザー登録成功');
                },
                error: function (err) {
                    _this.toastrService.warning(server + user['name'] + '  ユーザー登録失敗');
                    console.warn(err);
                    _this.toastrService.warning(server + err['error']['errors']['full_messages'].join('\n'));
                    console.warn(server + err['error']['errors']['full_messages'].join('\n'));
                }
            });
        };
        var this_1 = this;
        for (var _i = 0, users_1 = users; _i < users_1.length; _i++) {
            var user = users_1[_i];
            _loop_1(user);
        }
    };
    UserRegistrationService.prototype.getUserList = function () {
        return _super.prototype.requestGet.call(this, 'api/v1/users');
    };
    UserRegistrationService.prototype.freezeUser = function (id) {
        return _super.prototype.requestPost.call(this, 'api/v1/users/freeze_user', { id: id });
    };
    UserRegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function UserRegistrationService_Factory() { return new UserRegistrationService(i0.inject(i1.HttpHeaderService), i0.inject(i2.HttpClient), i0.inject(i3.ToastrService), i0.inject(i4.CompanyService), i0.inject(i5.SyncToStgService)); }, token: UserRegistrationService, providedIn: "root" });
    return UserRegistrationService;
}(HttpClientAbstract));
export { UserRegistrationService };

import { MailInfo } from './../../model/mail-info.model';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorFeedbackService } from '../../service/error-feedback.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-feedback',
  templateUrl: './error-feedback.component.html',
  styleUrls: ['./error-feedback.component.scss']
})
export class ErrorFeedbackComponent implements OnInit {
  @Input() mailInfo: MailInfo;
  @Input() mailType: string;
  @Input() page: string;
  @Input() matchingScoreId: number;
  errorFeedbackInfos: any;
  errorMessage: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private errorFeedbackService: ErrorFeedbackService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    const params = {
      mail_type: this.mailType,
      ai_mail_id: this.mailInfo.ai_mail_id,
      page: this.page
    };
    this.errorFeedbackService.getErrorFeedbackInfo(params).subscribe(
      res => {
        this.errorFeedbackInfos = res.body.error_feedback_infos;
      },
      error => {}
    );
  }

  sendErrorFeedback() {
    // formのバリデーションとapiに送信するparamsの生成
    let isValid = false;
    const params = {
      error_feedback: [],
      mail_type: this.mailType,
      ai_mail_id: this.mailInfo.ai_mail_id,
      matching_score_id: this.matchingScoreId
    };
    for (const i in this.errorFeedbackInfos) {
      if (this.errorFeedbackInfos.hasOwnProperty(i)) {
        // paramsのerror_feedbackキーにエラーフェードバック情報をpush
        params.error_feedback.push(this.errorFeedbackInfos[i].error_feedback);
        // tslint:disable-next-line:max-line-length
        if (
          this.errorFeedbackInfos[i].error_feedback.format_choice_answer ||
          this.errorFeedbackInfos[i].error_feedback.format_text_answer
        ) {
          isValid = true;
        }
      }
    }
    if (!isValid) {
      this.errorMessage = true;
    } else {
      this.errorFeedbackService.saveErrorFeedbackInfo(params).subscribe(
        res => {
          // エラー報告・削除モーダルを閉じる
          this.activeModal.close(res);
          this.toastrService.success("エラー報告に成功しました。\n ページを更新します。");
          // 削除後にページをリフレッシュ
          setTimeout('location.reload();', 1000);
        },
        error => {
          this.toastrService.error('エラー報告に失敗しました。');
          this.activeModal.close(error);
        }
      );
    }
  }
}
